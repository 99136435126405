import React from "react";
import { makeStyles } from "@material-ui/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import UnselfishMenu from "components/UnselfishMenu/UnselfishMenu";
import HelpIcon from "@material-ui/icons/Help";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { image1 } from "./images";
import { removeTokens } from "Auth/actions";
import { connect } from "react-redux";
import UserAction from "BaseApp/actions/User";
import UserSelector from "BaseApp/selectors/User";
import Link from "@material-ui/core/Link";
import tutorialPDF from "assets/tutorial.pdf";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex"
  },
  grow: {
    flexGrow: 1
  }
}));

const mapDispatch = dispatch => ({
  removeTokens: () => dispatch(removeTokens()),
  openAccountDialogAction: () =>
    dispatch(UserAction.setAccountDialogOpen(true)),
  openDocumentDialogAction: contentComponent =>
    dispatch(UserAction.setDocumentDialogContent(contentComponent))
});

const mapStateToProps = state => ({
  version: UserSelector.getVersion(state)
});

export const UserZone = props => {
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [helpMenuAnchorEl, setHelpMenuAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleAccountCircleOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  function handleHelpButtonClick(event) {
    setHelpMenuAnchorEl(event.currentTarget);
  }

  function handleHelpMenuClose() {
    setHelpMenuAnchorEl(null);
  }

  function signOut() {
    const { removeTokens } = props;
    removeTokens();
  }

  const renderProfileMenu = (
    <UnselfishMenu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        test-data="accountItem"
        onClick={e => props.openAccountDialogAction()}
      >
        <p>My account</p>
      </MenuItem>
      <MenuItem>
        <p onClick={signOut}>Sign out</p>
      </MenuItem>
    </UnselfishMenu>
  );

  const renderHelpMenu = (
    <UnselfishMenu
      anchorEl={helpMenuAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(helpMenuAnchorEl)}
      onClose={handleHelpMenuClose}
    >
      <MenuItem>
        <Link
          href="https://www.planopsim.com/requestpage"
          className={classes.link}
          color="inherit"
          style={{ textDecoration: "none" }}
        >
          Contact support
        </Link>
      </MenuItem>
      <MenuItem>
        <Link
          href="https://www.planopsim.com/requestpage"
          className={classes.link}
          color="inherit"
          style={{ textDecoration: "none" }}
        >
          Report a bug
        </Link>
      </MenuItem>
      <MenuItem>
        <p
          style={{ margin: 0, width: "100%", height: "100%" }}
          onClick={() => props.openDocumentDialogAction(tutorialPDF)}
        >
          Tutorial
        </p>
      </MenuItem>
      <MenuItem>
        <Link
          href="https://www.youtube.com/channel/UCKopwkebsQIvtUkknfVaEaQ/playlists"
          className={classes.link}
          target="_blank"
          rel="noreferrer"
          color="inherit"
          style={{ textDecoration: "none" }}
        >
          Tutorial videos
        </Link>
      </MenuItem>
      <div
        style={{
          borderTop: "solid grey 1px",
          marginLeft: "10px",
          marginRight: "10px"
        }}
      >
        <Typography style={{ margin: "10px", marginBottom: 0 }}>
          Build Date -
        </Typography>
        <Typography
          test-data="buildVersion"
          style={{ margin: "10px", marginTop: 0 }}
        >
          {props.version}
        </Typography>
      </div>
    </UnselfishMenu>
  );

  return (
    <div className={classes.root} test-data="userzone">
      <AppBar position="static" color="default">
        <Toolbar>
          <img src={image1} alt="logo" width="170" />
          <div className={classes.grow} />
          <IconButton
            aria-haspopup="true"
            onClick={handleAccountCircleOpen}
            color="primary"
          >
            <AccountCircle />
          </IconButton>
          <IconButton
            test-data="helpBtn"
            color="primary"
            aria-label="Help menu"
            onClick={handleHelpButtonClick}
          >
            <HelpIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderProfileMenu}
      {renderHelpMenu}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatch)(UserZone);
